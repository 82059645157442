<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <!-- <ayl-table :table="table">
        </ayl-table> -->
        <el-form :model="formTable" ref="form" :inline="true" label-position="left" style="display: inline;" class="search-box">
          <el-form-item label="标段名称" style="width: 240px;">
            <el-cascader
              v-model="formTable.bidId"
              :options= 'common_getBidNameAndIdList'
              :show-all-levels="false"
              collapse-tags
              ref="tree"
              @change="clickPersonType"
              style="width: 160px;"
              clearable></el-cascader>
          </el-form-item>
          <el-form-item label="审核状态" style="width: 260px;">
              <el-select
              v-model="formTable.checkStatus"
              allow-create
              default-first-option
              @change="clickPersonType"
              placeholder="请选择"
              style="width: 180px">
              <el-option
              v-for="(item, idx) in $enums_hw.checkStatusType.list"
              :key="idx"
              :label="item.label"
              :value="item.value">
              </el-option>
              </el-select>
          </el-form-item>
        </el-form>
        <div slot="ctrl-button" class="mb24px mb24px" style="display: inline;vertical-align: middle;">
          <el-button type="primary" @click="clickPersonType()">查 询</el-button>
        </div>
        <el-table :data="newBidCommitData" class="mt20px" style="width: 100%" size="medium">
          <el-table-column
            type="index"
            width="50">
          </el-table-column>
          <el-table-column label="标段名称">
            <template slot-scope="scope">
              <el-button @click="blockName(scope.row)" type="text" size="medium" style="color: #222;">{{ scope.row.bidName }}</el-button>
                <!-- <el-button @click="blockName(scope.row)" type="text" size="medium" style="color: #1ABC9C;">{{ scope.row.bidName }}</el-button> -->
            </template>
          </el-table-column>
          <el-table-column prop="bidNum" label="标段编号">
          </el-table-column>
          <el-table-column prop="bidSuperviseUnit" label="监管单位">
            <template slot-scope="scope">
                <span>{{ scope.row.bidSuperviseUnit || '- -' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="bidNum" label="养护企业">
          </el-table-column>
          <el-table-column prop="bidMaintainUnit" label="标段编号">
          </el-table-column>
          <el-table-column label="提交时间">
            <template slot-scope="scope">
                <span>{{ scope.row.commitTime|str2ymdhm }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核时间">
            <template slot-scope="scope">
                <span>{{ scope.row.checkCommitTime|str2ymdhm }}</span>
            </template>
          </el-table-column>
          <el-table-column label="审核进度">
              <template slot-scope="scope">
                <el-progress :text-inside="true" :stroke-width="16" :percentage="70" status="success"></el-progress>
                  <!-- <span>{{ scope.row.checkStatus }}</span> -->
              </template>
          </el-table-column>
          <el-table-column label="状态">
              <template slot-scope="scope">
                  <span>{{ $enums_hw.checkStatusType.getName(scope.row.checkStatus) }}</span>
              </template>
          </el-table-column>
        </el-table>
        <div class="mt20px mb20px" style="height: 10px">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page.sync="currentPage"
                layout="total, prev, pager, next, jumper"
                :total="totalNum"
                style="float: right"
                >
            </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [
          {name: '标段审核记录'}
        ],
        bidCommitData: [],
        newBidCommitData: [],
        common_getBidNameAndIdList: [],
        totalNum: 0,
        currentPage: 1,
        pageSize: 10,
        formTable: {
          checkStatus: null,
          bidId: "",
        },
      }
    },
    methods: {
      /**
       * 标段名称
       */
      blockName(val){
        console.log(val)
        // this.$router.push({
        //   path:'/district-management/block-audit/block-audit-details',
        //   query: {
        //     id: val.commitId
        //   }
        // });
      },
      /**
       * 分页
       */
      async handleCurrentChange(val) { 
          let start = (val-1) * this.pageSize
          let nextStart = start + this.pageSize
          if(this.totalNum > nextStart){
              this.newBidCommitData = this.bidCommitData
          }else{
              let newBidCommitData = await this.$api_hw.bidCommit_page({
                currentPage: val,
                pageSize: start,
              })
              this.newBidCommitData = newBidCommitData.data
          }
      },
      //
      async clickPersonType(){
        let allBidCommitData = await this.$api_hw.bidCommit_page({
          checkStatus: this.formTable.checkStatus,
          bidId: this.formTable.bidId[1],
          currentPage: this.currentPage,
          pageSize: this.pageSize,
        })
        this.bidCommitData = allBidCommitData.data
        this.newBidCommitData = allBidCommitData.data.slice(0,this.pageSize)
        this.totalNum = allBidCommitData.totalNum
      },

    },
    async mounted () {
      //await this.$search(this.table)
      let bidCommitData = await this.$api_hw.bidCommit_page({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      })
      this.bidCommitData = bidCommitData.data
      this.newBidCommitData = bidCommitData.data.slice(0,this.pageSize)
      this.totalNum = bidCommitData.totalNum
      this.common_getBidNameAndIdList = await this.$api_hw.common_getBidNameAndIdList({});
    }
  }
</script>

<style lang='sass' scoped>
  /deep/.el-drawer__header 
    margin-bottom: 15px
    padding-left: 15px
    color: #222
    font-size: 16px
  /deep/.el-button--success 
    color: #FFFFFF
    background-color: $-color-primary-3
    border-color: $-color-primary-3
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active
    background-color: $-color-primary-3
    color: #FFFFFF
  /deep/.el-table thead 
    color: #606266
  /deep/.el-button--primary
    color: #FFFFFF
    background-color: $-color-primary-3
    border-color: $-color-primary-3

</style>
